var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        "append-to-body": true,
        "modal-append-to-body": true,
        "with-header": false,
        direction: "rtl",
        "close-on-click-modal": false,
        visible: _vm.visible,
        size: _vm.width
      },
      on: {
        "update:visible": function($event) {
          _vm.visible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        _vm.component,
        _vm._g(
          _vm._b({ tag: "component" }, "component", _vm.info, false),
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }